//TOKEN
export const TOKEN = 'AUTH_TOKEN';

// export const GRAPHQL_ENDPOINT_HTTPS = 'https://changfamily.scg.co.th/graphql';
// export const GRAPHQL_ENDPOINT_WSS = 'wss://changfamily.scg.co.th/graphql';
// Production
export const GRAPHQL_ENDPOINT_HTTPS = process.env.REACT_APP_GRAPHQL_ENDPOINT_HTTPS;
// export const GRAPHQL_ENDPOINT_WSS = 'wss://changfamily-api.nexterdigitals-dev.com/graphql/';
// //
// export const GRAPHQL_ENDPOINT_HTTPS = 'http://localhost:4001/graphql';
// export const GRAPHQL_ENDPOINT_WSS = 'ws://localhost:4005/ws';
//Development
// export const GRAPHQL_ENDPOINT_HTTPS = 'https://changfamily.scg.co.th/api/v3/uat';
// export const GRAPHQL_ENDPOINT_WSS = 'wss://changfamily.scg.co.th/api/v3/uat';

//Local Development
// export const GRAPHQL_ENDPOINT_HTTPS = 'http://localhost:5099/dev-graphql';
// export const GRAPHQL_ENDPOINT_WSS = 'ws://localhost:5099/dev-ws';

//Local Development
// export const GRAPHQL_ENDPOINT_HTTPS = 'http://localhost:4005/graphql';
// const GRAPHQL_ENDPOINT_WSS = 'ws://localhost:4000/ws';
